<template>
  <div class="login">
    <div class="whole-box">
      <div class="upper-box">
        <img alt="Grasp logo" src="../assets/GRASP_SMALL.png">
      </div>
      <hr>
      <div class="lower-box">
        <p>Sign in choosing one option:</p>
        <!--   Firebase authenticattion -->
        <div id="firebaseui-auth-container"></div>
        <div id="loader">Loading...</div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth, analytics, firebase, userRef } from '@/plugins/firebaseInit'
import 'firebaseui/dist/firebaseui.css'

const firebaseui = require('firebaseui')

export default {
  name: 'Login',
  components: {},
  data () {
    return {
      ui: null,
      uiConfig: null,
      user: null
    }
  },
  methods: {
    initUi () {
      this.ui = firebaseui.auth.AuthUI.getInstance()
      if (!this.ui) {
        this.ui = new firebaseui.auth.AuthUI(auth)
      }
      this.ui.start('#firebaseui-auth-container', this.uiConfig)
      // ui.disableAutoSignIn()
    },
    async getUserProfile (uid) {
      const docRef = userRef.doc(uid)
      // docRef.get().then((doc) => {
      const doc = await docRef.get()
      if (doc.exists) {
        return doc
      } else {
        return false
      }
      // }).catch((error) => {
      //   console.log('Error getting document:', error)
      //   return false
      // })
    },
    updateUserProfile (authObject) {
      if (authObject.additionalUserInfo.providerId === 'google.com') {
        const docRef = userRef.doc(authObject.user.uid)
        docRef.get().then((doc) => {
          if (doc.exists) {
            docRef.update(
              { ...authObject.additionalUserInfo }
            )
          } else {
            docRef.set(
              { ...authObject.additionalUserInfo }
            )
          }
        }).catch((error) => {
          console.log('Error getting document:', error)
        })
      } else if (authObject.additionalUserInfo.providerId === 'password') {
        const userData = {
          ...authObject.additionalUserInfo,
          profile: {
            email: authObject.user.email,
            family_name: authObject.user.displayName,
            given_name: authObject.user.displayName,
            hd: authObject.user.email.split('@')[1],
            id: authObject.user.email,
            locale: 'en',
            name: authObject.user.displayName,
            picture: authObject.user.photoURL,
            verified_email: authObject.user.emailVerified
          }
        }
        const docRef = userRef.doc(authObject.user.uid)
        docRef.get().then((doc) => {
          if (doc.exists) {
            docRef.update(userData)
          } else {
            docRef.set(userData)
          }
        }).catch((error) => {
          console.log('Error getting document:', error)
        })
      } else {
        alert('Unsupported authentication provider!')
      }
    }
  },
  created () {
    // `this` points to the vm instance
  },
  mounted () {
    // `this` points to the vm instance
    const that = this
    this.uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult (authResult) {
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          var user = authResult.user
          analytics.setUserProperties({ email: user.email })
          // if (!user.emailVerified) {
          //   that.$store.dispatch('logOut')
          //   alert('Please verify your email first.')
          //   return false
          // }
          that.getUserProfile(user.uid)
            .then((userProfile) => {
              // console.log('User profile:')
              // console.log(userProfile)
              if (!userProfile) {
                that.$store.dispatch('logOut')
                alert('User not found.')
                that.initUi()
                // that.$router.push('/login')
              } else {
                user.getIdToken().then(function (idToken) {
                  authResult.user.token = idToken
                })
                that.$store.commit('loginSuccess', authResult)
                that.updateUserProfile(authResult)
                // console.log(authResult)
                that.$store.dispatch('updateTenant')
                that.$router.push('/live-view')
              }
            })

          return false
        },
        uiShown () {
          // The widget is rendered.
          // Hide the loader.
          document.getElementById('loader').style.display = 'none'
        }
      },
      // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
      signInFlow: 'popup',
      signInSuccessUrl: '/live-view',
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          // provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          // scopes: [
          //   'https://www.googleapis.com/auth/contacts.readonly',
          // ],
          customParameters: {
            // Forces account selection even when one account
            // is available.
            prompt: 'select_account'
          }
        },
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          buttonColor: '#2d1d60'
        }
      ],
      // Terms of service url.
      tosUrl: '/about',
      // Privacy policy url.
      privacyPolicyUrl: '/about'
    }
    // Initialize the FirebaseUI Widget using Firebase.
    this.initUi()
  }
}
</script>

<style scoped>
.whole-box {
  margin: 50px auto 0;
  max-width: 350px;
  box-shadow: 0 3px 9px rgb(0, 0, 0, 10%);
  /* box-sizing: border-box; */
  border: 0.5px solid #2d1d6060;
  border-radius: 15px;
  background: rgb(0, 0, 0, 3%);
}

.lower-box {
  padding: 20px;
}

img {
  margin: 25px auto;
}

hr {
  width: 75%;
  border-top: 0.5px solid #2d1d6060;
  border-bottom: none;
}

</style>
